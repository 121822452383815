.Chatbot {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

html,
.Chatbot {
  margin: 0px;
  height: 100%;
}

.Chatbot {
  font-size: 16px;
  line-height: 24px;
  font-family: "ColfaxAI", Helvetica, sans-serif;
}

input {
  font-size: 16px;
  line-height: 24px;
  font-family: "ColfaxAI", Helvetica, sans-serif;
}

::placeholder {
  color: #8e8ea0;
  opacity: 1;
}

.chatbot {
  width: 99%;
  min-height: 400px;
  position: relative;
}

@media (min-width: 467px) {
  .chatbot {
    width: 467px;
  }
}

#inputContainer, #inputbar, #inputHintBar {
  margin-top: 7px;
  margin-bottom: 7px;
}

.chatContainer {
  overflow-y: scroll;
  height: calc(100vh - 245px) ;
}

.inputContainer {
  height:116px;
  width:100%;
  bottom:0px;
}

@media (min-width: 467px) {
  .chatContainer {
    height: calc(100vh - 238px) ;
  }
}