.navbar {
    position: sticky;
    z-index: 1000;
    top: 0px;
    width: 100%;
    background-color: #ffffff;
}

.navMenu {
    position: static;
}

.logo {
    display: none;
    position: fixed;
    z-index: 10;
    left: 20px;
}

.logo>img {
    width: 64px;
}