.bubble {
  padding: 6px;
  overflow: hidden;
  position: relative;
}

.bubble::after {
  content: "";
  clear: both;
}

.bubble .content {
  margin: 0px;
  width: auto;
  padding: 0.7em 0.5em;
  font-size: 16px;
  border-radius: 6px;
  border: 1px solid;
  position: relative;
  display: inline-block;
}

.bubble .content:before {
  content: '';
  display: inline-block;
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  border: 1px solid transparent;
  border-radius: 3px;
  position: absolute;
  background-color: inherit;
  border-right-color: inherit;
  border-bottom-color: inherit;
}

.bubble.left {
  text-align: left;
}

.bubble.left .content {
  background: #fafafa;
  border-color: #c6c6c6;
}

.bubble.left .content:before {
  left: -6px;
  top: 16px;
  transform: rotate(135deg);
}

.bubble.right {
  text-align: right;
}

.bubble.right .content {
  background: #b3e866;
  border-color: #9ab96b;
  text-align: left;
}

.bubble.right .content:before {
  right: -6px;
  top: 16px;
  transform: rotate(-45deg);
}

.wrap {
  margin-left: 56px;
  margin-right: 56px;
}



.bubble div.avatar {
  display: block;
  height: 44px;
  width: 44px;
  border-radius: 2px;
  overflow: hidden;
}

.bubble div.avatar>img {
  width: 100%;
  height: 100%;
}

.bubble.left div.avatar {
  float: left;
}

.bubble.right div.avatar {
  float: right;
}