html,
.AboutPage {
    margin: 0px;
    height: 100%;
    width: auto;
    background-color: #ffffff;
}

.pageContent {
    padding: auto;
    margin: auto;
    width: auto;
    max-width: 1440px;
    margin-bottom: 20px;
}