.table-drag-select,
.day-cell {
    /* Stretch horizontally to fill parent */
    width: 100%;
    /* Keep columns same width */
    table-layout: fixed;
    /* Disable text selection */
    user-select: none;
    /* Center in parent */
    margin-left: auto;
    margin-right: auto;
    /* Remove space between border */
    border-collapse: collapse;
}

/* Table cells */
.timestamp-cell,
.table-drag-select td {
    font-size: 0.6rem;
    text-align: center;
    /* Cut off long strings of text with ... */
    text-overflow: ellipsis;
    overflow: hidden;
    /* Make transparent gaps between cells. Better than relying on margin
     * because clicking an element's margin doesn't trigger events */
    border: 1px solid transparent;
    background-clip: padding-box;
    /* Make cells reasonable size */
    line-height: 0.6rem;
    /* Shows more text when text too big to fit into cell */
    padding-left: 0rem;
    padding-right: 0rem;
}

.timestamp-cell {
    /* Make cells reasonable size */
    line-height: 0.6rem;
    border-bottom: 2px solid transparent;
}

.day-cell {
    text-align: center;
}

/* Cells that can be interacted with */
.table-drag-select td.cell-enabled {
    background-color: #DDD;
    /* Make the cursor a + symbol */
    cursor: cell;
}

/* Cells that cannot be interacted with */
.table-drag-select td.cell-disabled {
    /* Put yer css here */
}

/* Cells that have been selected */
.table-drag-select td.cell-selected {
    background-color: #41c121;
}

/* Cells that are in the process of being selected */
.table-drag-select td.cell-being-selected {
    /* This is the blue color you see when you select text with the cursor */
    background-color: #3F83D5;
}