.carousel--image {
    width: inherit;
    max-width: 1440px;
}

.pageContent {
    padding: auto;
    margin: auto;
    width: 100%;
    max-width: 1040px;
    margin-bottom: 20px;
}

.slick-prev,
.slick-next {
    z-index: 10;
    color: #000000;
    opacity: 50%;
}

.slick-prev {
    left: 30px;
}

.slick-next {
    right: 30px;
}

.slick-prev:hover,
.slick-next:hover {
    color: #000000;
    opacity: 20%;
}

.slick-dots {
    bottom: 20px;
    left: auto;
}