@font-face {
    font-family: CustomFont;
    src: url("./fonts/Kulsix-lglgd.ttf");
}

@font-face {
    font-family: CustomCNFont;
    src: url("./fonts/NotoSansSC-Light.otf");
}

@font-face {
    font-family: Baloo2-ExtraBold;
    src: url("./fonts/Baloo2-ExtraBold.ttf");
}

@font-face {
    font-family: Heebo;
    src: url("./fonts/Heebo-VariableFont_wght.ttf");
}